import React, { useEffect } from "react";
import NavBar from "../Components/Navigation/Navigation";
import { useStoreState, useStoreActions } from "easy-peasy";
import Home from "./Home/Home";
import Chat from "./Chat/Chat";
import { getMessages } from "../Services/Messages/MessageDB_reqs";
import { useParams } from "react-router-dom";
import { getBotData } from "../Services/CustomBot/CustomBot_reqs";
import Lottie from "lottie-react";
// import webLoading from "../Assets/Animations/Website_loading.json";
import webLoading from "../Assets/Animations/wbsite_loading2.json";
import { motion } from "framer-motion";
import { url } from "../Services/Urls";

const Zappy = (props) => {
  let { id } = useParams();
  //Global States
  const pageNumber = useStoreState((state) => state.pageNumber);
  const setPageNumber = useStoreActions((actions) => actions.setPageNumber);
  const setChats = useStoreActions((actions) => actions.setChats);
  const setBusinessName = useStoreActions((actions) => actions.setBusinessName);
  const setLoggedIn = useStoreActions((actions) => actions.setLoggedIn);
  const setUsername = useStoreActions((actions) => actions.setUsername);
  const setEmail = useStoreActions((actions) => actions.setEmail);
  const setBotData = useStoreActions((actions) => actions.setBotData);
  const botData = useStoreState((state) => state.botData);
  const setBotId = useStoreActions((actions) => actions.setBotId);

  //Local States
  const [loaded, setLoaded] = React.useState(false);
  const [shrink, setShrink] = React.useState(false);

  const firstMessages = async (name) => {
    try {
      await getMessages({
        email: localStorage.getItem("email"),
        username: localStorage.getItem("username"),
        bot_id: id,
      })
        .then((res) => {
          setChats(res.data.data);
          setTimeout(() => {
            setLoaded(true);
          }, 1000);

          setTimeout(() => {
            setShrink(true);
          }, 800);
        })
        .catch((err) => {
          let chats = [
            {
              type: "AI",
              text: "Hi I'm Zappy !  ^-^",
            },
            {
              type: "AI",
              text: "Lets get to know each other a bit better.",
            },
            {
              type: "AI",
              text: "We’ll start with a few questions.",
            },
            {
              type: "AI",
              text: "What’s your name?",
            },
          ];
          setChats(chats);
          setTimeout(() => {
            setLoaded(true);
          }, 1000);
          setTimeout(() => {
            setShrink(true);
          }, 800);
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (props.page === 0) {
      id = process.env.REACT_APP_ZAPPY_ID;
    }
    getBotData({"bot_id":id,"url":window.location.href})
      .then((res) => {
        console.log(res.data.data);
        // setBusinessName(res.data.data.name);
        // setPages(res.data.data.pages);
        // setSuggestions(res.data.data.suggestions);
        // setLoaded(true);

        setBotData({
          ...botData,
          name: res.data.data.bot_name,
          pages: res.data.data.pages,
          suggestions: [res.data.data.suggestions],
          logo: res.data.data.logo,
          welcome_message: res.data.data.welcome_message,
          subtext: res.data.data.subtext,
          button_text: res.data.data.button_text,
          template: res.data.data.template,
          social: res.data.data.social,
        });
        setBotId(res.data.data._id);
        console.log("bot id"+res.data.data._id);
        setBusinessName(res.data.data.bot_name);
        document.title = res.data.data.bot_name; //adding title to the page
        const link = document.createElement("link"); //adding favicon to the page
        link.rel = "icon";
        link.href = res.data.data.template.favicon != undefined ? `data:image/png;base64,${res.data.data.template.favicon}` : "https://getzapmail.com/wp-content/uploads/2023/11/cropped-Mask-group-192x192.png";
        document.head.appendChild(link);

        const description = document.createElement("meta"); //adding description to the page SEO
        description.name = "description";
        description.content = res.data.data.description;
        document.head.appendChild(description);

        firstMessages(res.data.data.bot_name);
        if (localStorage.getItem("username") !== null) {
          setUsername(localStorage.getItem("username"));
          setEmail(localStorage.getItem("email"));
          setLoggedIn(true);
        }
      })
      .catch((err) => {
        console.log(err);
        window.location.href = "/bot/404";
      });
    props.page === 1 && setPageNumber(0);
    props.page === 2 && setPageNumber(1);
  }, [loaded]);

  return (
    <>
      {loaded ? (
        <>
          <NavBar />
          <motion.div
            initial={{ opacity: 0, scale: 1.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.3,
              ease: [0, 0.71, 0.2, 1.01],
              scale: {
                type: "spring",
                damping: 20,
                stiffness: 100,
                restDelta: 0.001,
              },
            }}
          >
            {pageNumber === 0 && loaded && <Home />}
            {pageNumber === 1 && loaded && <Chat page={props.page === 0 ? 0 : 1}/>}
          </motion.div>
        </>
      ) : shrink ? (
        <motion.div
          initial={{ opacity: 1, scale: 1 }}
          animate={{ opacity: 0, scale: 0.5 }}
          transition={{
            duration: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
            scale: {
              type: "spring",
              damping: 20,
              stiffness: 100,
              restDelta: 0.001,
            },
          }}
        >
          <div
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Lottie
              animationData={webLoading}
              loop={true}
              style={{
                width: "14rem",
              }}
            ></Lottie>
          </div>
        </motion.div>
      ) : (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Lottie
            animationData={webLoading}
            loop={true}
            style={{
              width: "14rem",
            }}
          ></Lottie>
        </div>
      )}
    </>
  );
};

export default Zappy;
