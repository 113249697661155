import axios from "axios";
import { protocol, url } from "../Urls";

export const getBotData = (data) => { // Getting bot data from id or url
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",
      baseURL: `${protocol}${url}`,
      url: `/getBot?bot_id=${data["bot_id"]}&url=${data["url"].replace(/^https?:\/\//, '').replace(/^http?:\/\//, '')}`, // remove http:// or https:// from the url
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const runCustomBot = (data) => { // Getting bot response
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "post",
        baseURL: `${protocol}${url}`,
        url: "/runCustomBot",
        data: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };